import { config } from '../cognitocmsapi/default_config.js'
import templates from './templates.js'

config.devURL = `https://zander.${config.devbase}`
config.prodURL = 'https://cms.zandertektalks.com.au'
config.siteURL = 'https://zandertektalks.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.group_args.image_aspect = '4x3'

config.isEcommerce = true
config.sentryDsn = 'https://930d250ce4364b76a1ca2593a6457df9@glitchtip.logger.jm1.au/16'

config.siteSpecificTemplates = templates

export { config }
