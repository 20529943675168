<template>
  <div class="relative">
    <div class="gap-10 pb-20 pt-16 sm:flex">
      <div class="relative mx-auto max-w-7xl">
        <div class="flex flex-col gap-4 md:flex-row">
          <h2
            class="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl"
          >
            <div>Featured products</div>
          </h2>
          <cgn-button color-brand url="/shop/featured">
            Shop now
          </cgn-button>
        </div>
        <div
          class="mx-auto mt-8 grid max-w-lg gap-5 md:max-w-none md:grid-cols-3"
        >
          <router-link
            v-for="product in products"
            :key="product.slug"
            :to="`/product/${product.slug}`"
            class="flex flex-col overflow-hidden rounded-lg shadow-lg"
          >
            <div class="shrink-0">
              <cgn-lazy-image
                class="w-full bg-white"
                :image="product.image"
              />
            </div>
            <div
              class="flex flex-1 flex-col justify-between bg-white p-6 dark:bg-gray-800 sm:flex-row"
            >
              <div class="flex flex-1 flex-col">
                <p
                  class="text-xl font-semibold text-gray-900 dark:text-gray-100"
                >
                  {{ product.name }}
                </p>
                <div
                  class="mt-2 text-base text-gray-400 dark:text-gray-300"
                  v-html="product.blurb"
                />
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SellProduct } from '~cognito/models/Sell/Product'

const products = ref<SellProduct[]>([])

function loadProducts() {
  new SellProduct().find_many({
    image_aspect: '4by3',
    image_width: 600,
    page_size: 3,
    orderby: 'random',
    group: 'featured',
    page: 1,
  }).then((data) => {
    products.value = data.data
  })
}
onMounted(() => {
  loadProducts()
})
onServerPrefetch(async () => {
  await loadProducts()
})
</script>
