<template>
  <div class="mx-auto mt-12 flex flex-col gap-5">
    <div
      v-for="shopGroup in shopGroups"
      :key="shopGroup.slug"
    >
      <div
        v-if="shopGroup.content && shopGroup.image.url"
        class="overflow-hidden rounded-lg shadow-lg"
      >
        <div class="flex flex-col justify-between gap-2 bg-white p-4 dark:bg-gray-800 sm:flex-row">
          <div class="flex flex-1 flex-col">
            <router-link :to="`/shop/${shopGroup.slug}`" class="flex flex-1 flex-col">
              <div class="flex items-center justify-between gap-2">
                <p
                  class="text-xl font-semibold text-gray-900 dark:text-gray-100"
                >
                  {{ shopGroup.name }}
                </p>
                <cgn-button color-brand>
                  Shop Now
                </cgn-button>
              </div>
              <div
                class="prose-brand prose text-sm dark:prose-dark"
                v-html="shopGroup.content"
              />
            </router-link>
          </div>
          <router-link :to="`/shop/${shopGroup.slug}`">
            <cgn-lazy-image
              class="aspect-square h-full w-full overflow-hidden rounded-md object-cover sm:h-48 sm:w-48"
              :image="shopGroup.image"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'

const shopGroups = ref<CognitoGroup[]>([])
async function loadGroups() {
  const data = await new CognitoGroup().find_many({
    namespace: 'Sell',
    model: 'Product',
  })
  shopGroups.value = data
}
onMounted(() => {
  loadGroups()
})
onServerPrefetch(async () => {
  await loadGroups()
})
</script>
