<template>
  <div class="mx-auto max-w-5xl p-6">
    <div class="pt-10 lg:flex lg:items-center lg:justify-between">
      <div>
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          <span class="block sm:inline-block">Welcome to</span>
          <span class="text-brand-600 dark:text-brand-500 sm:pl-2">ZanderTekTalks</span>
        </h2>
        <p class="mt-4 block max-w-lg text-lg leading-6 text-gray-700 dark:text-gray-200">
          Selling 3D printed products
        </p>
      </div>
      <div class="mt-8 flex gap-4 lg:mt-0 lg:shrink-0">
        <cgn-button color-brand url="/shop">
          Shop now
        </cgn-button>
        <cgn-button color-brand url="/blog">
          Articles
        </cgn-button>
      </div>
    </div>
    <productFeatured />
    <GroupPromo />
  </div>
</template>
